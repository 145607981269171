@charset "UTF-8";
body:before {
  background-color: #FCF8E3;
  border-bottom: 1px solid #FBEED5;
  border-left: 1px solid #FBEED5;
  color: #C09853;
  font: small-caption;
  padding: 3px 6px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: none; }
  @media all {
    body:before {
      content: "xs ≥ 0px (0em)"; } }
  @media (min-width: 36em) {
    body:before {
      content: "sm ≥ 576px (36em)"; } }
  @media (min-width: 47.9375em) {
    body:before {
      content: "md ≥ 767px (47.9375em)"; } }
  @media (min-width: 62em) {
    body:before {
      content: "lg ≥ 992px (62em)"; } }
  @media (min-width: 75em) {
    body:before {
      content: "xl ≥ 1200px (75em)"; } }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
table {
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #fff;
  border-bottom: 1px solid #c9c6c0; }

th {
  text-align: left; }

.table {
  width: 100%; }
  .table th,
  .table td {
    padding: 8px;
    border-top: 1px solid #c9c6c0;
    line-height: 1.5em;
    vertical-align: top;
    width: 50%;
    border-right: 1px solid #c9c6c0; }
  .table thead th {
    font-weight: 700;
    vertical-align: bottom; }
  .table caption + thead tr:first-child th,
  .table caption + thead tr:first-child td,
  .table colgroup + thead tr:first-child th,
  .table colgroup + thead tr:first-child td,
  .table thead:first-child tr:first-child th,
  .table thead:first-child tr:first-child td {
    border-top: 0; }
  .table tbody + tbody {
    border-top: 2px solid #c9c6c0; }
  .table .table {
    background-color: #FFFFFF; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
body:before {
  background-color: #FCF8E3;
  border-bottom: 1px solid #FBEED5;
  border-left: 1px solid #FBEED5;
  color: #C09853;
  font: small-caption;
  padding: 3px 6px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: none; }
  @media all {
    body:before {
      content: "xs ≥ 0px (0em)"; } }
  @media (min-width: 36em) {
    body:before {
      content: "sm ≥ 576px (36em)"; } }
  @media (min-width: 47.9375em) {
    body:before {
      content: "md ≥ 767px (47.9375em)"; } }
  @media (min-width: 62em) {
    body:before {
      content: "lg ≥ 992px (62em)"; } }
  @media (min-width: 75em) {
    body:before {
      content: "xl ≥ 1200px (75em)"; } }

html {
  font-size: 100%;
  line-height: 1.5; }
  @media all and (min-width: 0px) {
    html {
      font-size: 75%;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    html {
      font-size: 87.5%;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    html {
      font-size: 100%;
      line-height: 1.5; } }
  @media all and (min-width: ) {
    html {
      font-size: 125%;
      line-height: 1.5; } }

@font-face {
  font-family: "Stockholm Type Bold";
  src: url("../../../fonts/StockholmType-Bold.otf") format("otf"), url("../../../fonts/StockholmType-Bold.ttf") format("ttf"), url("../../../fonts/StockholmType-Bold.woff") format("woff"); }

@font-face {
  font-family: "Stockholm Type Regular";
  src: url("../../../fonts/StockholmType-Regular.otf") format("otf"), url("../../../fonts/StockholmType-Regular.ttf") format("ttf"), url("../../../fonts/StockholmType-Regular.woff") format("woff"); }

/********** PRAGMATIC FONT *******/
h1 {
  font-family: Stockholm Type Bold, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #333333; }
  @media all and (min-width: 0px) {
    h1 {
      font-size: 1.80203em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    h1 {
      font-size: 1.80203em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    h1 {
      font-size: 1.80203em;
      line-height: 1.5; } }

h2 {
  font-family: Stockholm Type Bold, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #333333; }
  @media all and (min-width: 0px) {
    h2 {
      font-size: 1.60181em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    h2 {
      font-size: 1.60181em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    h2 {
      font-size: 1.60181em;
      line-height: 1.5; } }

h3 {
  font-family: Stockholm Type Bold, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #333333; }
  @media all and (min-width: 0px) {
    h3 {
      font-size: 1.42383em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    h3 {
      font-size: 1.42383em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    h3 {
      font-size: 1.42383em;
      line-height: 1.5; } }

h4 {
  font-family: Stockholm Type Bold, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #333333; }
  @media all and (min-width: 0px) {
    h4 {
      font-size: 1.26563em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    h4 {
      font-size: 1.26563em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    h4 {
      font-size: 1.26563em;
      line-height: 1.5; } }

p {
  font-family: Open sans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #747474; }
  @media all and (min-width: 0px) {
    p {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    p {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    p {
      font-size: 1em;
      line-height: 1.5; } }

blockquote,
a {
  font-family: Open sans, Helvetica, Arial, sans-serif;
  font-weight: 400; }
  @media all and (min-width: 0px) {
    blockquote,
    a {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    blockquote,
    a {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    blockquote,
    a {
      font-size: 1em;
      line-height: 1.5; } }

a {
  text-decoration: none;
  font-weight: 500;
  color: #0664aa; }

.textAnchor--link {
  color: #0664aa; }

.textRegular {
  font-family: "Stockholm Type Regular"; }

li,
ol,
ul,
input,
label {
  font-family: Open sans, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: 400; }
  @media all and (min-width: 0px) {
    li,
    ol,
    ul,
    input,
    label {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 767px) {
    li,
    ol,
    ul,
    input,
    label {
      font-size: 1em;
      line-height: 1.5; } }
  @media all and (min-width: 1200px) {
    li,
    ol,
    ul,
    input,
    label {
      font-size: 1em;
      line-height: 1.5; } }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.listFormated {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__item {
  margin-bottom: 10px; }

.bulletList {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px; }

blockquote {
  position: relative;
  margin: 0;
  padding: 40px 40px;
  text-align: left;
  font-size: 1em; }

blockquote:before,
blockquote:after {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: 6em;
  line-height: 1; }

blockquote:before {
  top: 0;
  left: 0;
  content: "\201C"; }

blockquote:after {
  bottom: -20px;
  right: 0;
  content: "\201D"; }

.styleguide__componentDidivder {
  padding: 10px;
  color: #000;
  background-color: #E5E6EB;
  margin-top: 160px;
  margin-bottom: 30px; }

.styleguide__subTitle {
  margin-top: 30px; }

.noScrollFake {
  position: fixed;
  overflow-y: scroll;
  width: 100%; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-none {
  float: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.center {
  margin: 0 auto; }

.full-viewport-height {
  height: 100vh; }
  @media (max-width: 35.99em) {
    .full-viewport-height {
      height: auto; } }

.center {
  margin: 0 auto; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.uppercase {
  text-transform: uppercase; }

.padding-xs {
  padding: 1em; }

.padding-sm {
  padding: 2em; }
  @media (max-width: 35.99em) {
    .padding-sm {
      padding: 1.5em; } }

.padding-md {
  padding: 3em; }
  @media (max-width: 35.99em) {
    .padding-md {
      padding: 2.5em; } }

.padding-lg {
  padding: 4em; }
  @media (max-width: 35.99em) {
    .padding-lg {
      padding: 3.5em; } }

.padding-xl {
  padding: 5em; }
  @media (max-width: 35.99em) {
    .padding-xl {
      padding: 4.5em; } }

.padding-xxl {
  padding: 7em; }
  @media (max-width: 35.99em) {
    .padding-xxl {
      padding: 6.5em; } }

.padding-y-xs {
  padding-top: 1em;
  padding-bottom: 1em; }

.padding-y-sm {
  padding-top: 2em;
  padding-bottom: 2em; }
  @media (max-width: 35.99em) {
    .padding-y-sm {
      padding-top: 1.5em;
      padding-bottom: 1.5em; } }

.padding-y-md {
  padding-top: 3em;
  padding-bottom: 3em; }
  @media (max-width: 35.99em) {
    .padding-y-md {
      padding-top: 2.5em;
      padding-bottom: 2.5em; } }

.padding-y-lg {
  padding-top: 4em;
  padding-bottom: 4em; }
  @media (max-width: 35.99em) {
    .padding-y-lg {
      padding-top: 3.5em;
      padding-bottom: 3.5em; } }

.padding-y-xl {
  padding-top: 5em;
  padding-bottom: 5em; }
  @media (max-width: 35.99em) {
    .padding-y-xl {
      padding-top: 4.5em;
      padding-bottom: 4.5em; } }

.padding-y-xxl {
  padding-top: 7em;
  padding-bottom: 7em; }
  @media (max-width: 35.99em) {
    .padding-y-xxl {
      padding-top: 6.5em;
      padding-bottom: 6.5em; } }

.padding-x-xs {
  padding-right: 1em;
  padding-left: 1em; }

.padding-x-sm {
  padding-right: 2em;
  padding-left: 2em; }
  @media (max-width: 35.99em) {
    .padding-x-sm {
      padding-right: 1.5em;
      padding-left: 1.5em; } }

.padding-x-md {
  padding-right: 3em;
  padding-left: 3em; }
  @media (max-width: 35.99em) {
    .padding-x-md {
      padding-right: 2.5em;
      padding-left: 2.5em; } }

.padding-x-lg {
  padding-right: 4em;
  padding-left: 4em; }
  @media (max-width: 35.99em) {
    .padding-x-lg {
      padding-right: 3.5em;
      padding-left: 3.5em; } }

.padding-x-xl {
  padding-right: 5em;
  padding-left: 5em; }
  @media (max-width: 35.99em) {
    .padding-x-xl {
      padding-right: 4.5em;
      padding-left: 4.5em; } }

.padding-x-xxl {
  padding-right: 7em;
  padding-left: 7em; }
  @media (max-width: 35.99em) {
    .padding-x-xxl {
      padding-right: 6.5em;
      padding-left: 6.5em; } }

.padding-t-xs {
  padding-top: 1em; }

.padding-t-sm {
  padding-top: 2em; }
  @media (max-width: 35.99em) {
    .padding-t-sm {
      padding-top: 1.5em; } }

.padding-t-md {
  padding-top: 3em; }
  @media (max-width: 35.99em) {
    .padding-t-md {
      padding-top: 2.5em; } }

.padding-t-lg {
  padding-top: 4em; }
  @media (max-width: 35.99em) {
    .padding-t-lg {
      padding-top: 3.5em; } }

.padding-t-xl {
  padding-top: 5em; }
  @media (max-width: 35.99em) {
    .padding-t-xl {
      padding-top: 4.5em; } }

.padding-t-xxl {
  padding-top: 7em; }
  @media (max-width: 35.99em) {
    .padding-t-xxl {
      padding-top: 6.5em; } }

.padding-b-xs {
  padding-bottom: 1em; }

.padding-b-sm {
  padding-bottom: 2em; }
  @media (max-width: 35.99em) {
    .padding-b-sm {
      padding-bottom: 1.5em; } }

.padding-b-md {
  padding-bottom: 3em; }
  @media (max-width: 35.99em) {
    .padding-b-md {
      padding-bottom: 2.5em; } }

.padding-b-lg {
  padding-bottom: 4em; }
  @media (max-width: 35.99em) {
    .padding-b-lg {
      padding-bottom: 3.5em; } }

.padding-b-xl {
  padding-bottom: 5em; }
  @media (max-width: 35.99em) {
    .padding-b-xl {
      padding-bottom: 4.5em; } }

.padding-b-xxl {
  padding-bottom: 7em; }
  @media (max-width: 35.99em) {
    .padding-b-xxl {
      padding-bottom: 6.5em; } }

.padding-l-xs {
  padding-left: 1em; }

.padding-l-sm {
  padding-left: 2em; }
  @media (max-width: 35.99em) {
    .padding-l-sm {
      padding-left: 1.5em; } }

.padding-l-md {
  padding-left: 3em; }
  @media (max-width: 35.99em) {
    .padding-l-md {
      padding-left: 2.5em; } }

.padding-l-lg {
  padding-left: 4em; }
  @media (max-width: 35.99em) {
    .padding-l-lg {
      padding-left: 3.5em; } }

.padding-l-xl {
  padding-left: 5em; }
  @media (max-width: 35.99em) {
    .padding-l-xl {
      padding-left: 4.5em; } }

.padding-l-xxl {
  padding-left: 7em; }
  @media (max-width: 35.99em) {
    .padding-l-xxl {
      padding-left: 6.5em; } }

.padding-r-xs {
  padding-right: 1em; }

.padding-r-sm {
  padding-right: 2em; }
  @media (max-width: 35.99em) {
    .padding-r-sm {
      padding-right: 1.5em; } }

.padding-r-md {
  padding-right: 3em; }
  @media (max-width: 35.99em) {
    .padding-r-md {
      padding-right: 2.5em; } }

.padding-r-lg {
  padding-right: 4em; }
  @media (max-width: 35.99em) {
    .padding-r-lg {
      padding-right: 3.5em; } }

.padding-r-xl {
  padding-right: 5em; }
  @media (max-width: 35.99em) {
    .padding-r-xl {
      padding-right: 4.5em; } }

.padding-r-xxl {
  padding-right: 7em; }
  @media (max-width: 35.99em) {
    .padding-r-xxl {
      padding-right: 6.5em; } }

.margin-xs {
  margin: 1em; }

.margin-sm {
  margin: 2em; }
  @media (max-width: 35.99em) {
    .margin-sm {
      margin: 1.5em; } }

.margin-md {
  margin: 3em; }
  @media (max-width: 35.99em) {
    .margin-md {
      margin: 2.5em; } }

.margin-lg {
  margin: 4em; }
  @media (max-width: 35.99em) {
    .margin-lg {
      margin: 3.5em; } }

.margin-xl {
  margin: 5em; }
  @media (max-width: 35.99em) {
    .margin-xl {
      margin: 4.5em; } }

.margin-xxl {
  margin: 7em; }
  @media (max-width: 35.99em) {
    .margin-xxl {
      margin: 6.5em; } }

.margin-y-xs {
  margin-top: 1em;
  margin-bottom: 1em; }

.margin-y-sm {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 35.99em) {
    .margin-y-sm {
      margin-top: 1.5em;
      margin-bottom: 1.5em; } }

.margin-y-md {
  margin-top: 3em;
  margin-bottom: 3em; }
  @media (max-width: 35.99em) {
    .margin-y-md {
      margin-top: 2.5em;
      margin-bottom: 2.5em; } }

.margin-y-lg {
  margin-top: 4em;
  margin-bottom: 4em; }
  @media (max-width: 35.99em) {
    .margin-y-lg {
      margin-top: 3.5em;
      margin-bottom: 3.5em; } }

.margin-y-xl {
  margin-top: 5em;
  margin-bottom: 5em; }
  @media (max-width: 35.99em) {
    .margin-y-xl {
      margin-top: 4.5em;
      margin-bottom: 4.5em; } }

.margin-y-xxl {
  margin-top: 7em;
  margin-bottom: 7em; }
  @media (max-width: 35.99em) {
    .margin-y-xxl {
      margin-top: 6.5em;
      margin-bottom: 6.5em; } }

.margin-x-xs {
  margin-right: 1em;
  margin-left: 1em; }

.margin-x-sm {
  margin-right: 2em;
  margin-left: 2em; }
  @media (max-width: 35.99em) {
    .margin-x-sm {
      margin-right: 1.5em;
      margin-left: 1.5em; } }

.margin-x-md {
  margin-right: 3em;
  margin-left: 3em; }
  @media (max-width: 35.99em) {
    .margin-x-md {
      margin-right: 2.5em;
      margin-left: 2.5em; } }

.margin-x-lg {
  margin-right: 4em;
  margin-left: 4em; }
  @media (max-width: 35.99em) {
    .margin-x-lg {
      margin-right: 3.5em;
      margin-left: 3.5em; } }

.margin-x-xl {
  margin-right: 5em;
  margin-left: 5em; }
  @media (max-width: 35.99em) {
    .margin-x-xl {
      margin-right: 4.5em;
      margin-left: 4.5em; } }

.margin-x-xxl {
  margin-bottom: 7em; }
  @media (max-width: 35.99em) {
    .margin-x-xxl {
      margin-bottom: 6.5em; } }

.margin-t-xs {
  margin-top: 1em; }

.margin-t-sm {
  margin-top: 2em; }
  @media (max-width: 35.99em) {
    .margin-t-sm {
      margin-top: 1.5em; } }

.margin-t-md {
  margin-top: 3em; }
  @media (max-width: 35.99em) {
    .margin-t-md {
      margin-top: 2.5em; } }

.margin-t-lg {
  margin-top: 4em; }
  @media (max-width: 35.99em) {
    .margin-t-lg {
      margin-top: 3.5em; } }

.margin-t-xl {
  margin-top: 5em; }
  @media (max-width: 35.99em) {
    .margin-t-xl {
      margin-top: 4.5em; } }

.margin-t-xxl {
  margin-top: 7em; }
  @media (max-width: 35.99em) {
    .margin-t-xxl {
      margin-top: 6.5em; } }

.margin-b-xs {
  margin-bottom: 1em; }

.margin-b-sm {
  margin-bottom: 2em; }
  @media (max-width: 35.99em) {
    .margin-b-sm {
      margin-bottom: 1.5em; } }

.margin-b-md {
  margin-bottom: 3em; }
  @media (max-width: 35.99em) {
    .margin-b-md {
      margin-bottom: 2.5em; } }

.margin-b-lg {
  margin-bottom: 4em; }
  @media (max-width: 35.99em) {
    .margin-b-lg {
      margin-bottom: 3.5em; } }

.margin-b-xl {
  margin-bottom: 5em; }
  @media (max-width: 35.99em) {
    .margin-b-xl {
      margin-bottom: 4.5em; } }

.margin-b-xxl {
  margin-bottom: 7em; }
  @media (max-width: 35.99em) {
    .margin-b-xxl {
      margin-bottom: 6.5em; } }

.margin-l-xs {
  margin-left: 1em; }

.margin-l-sm {
  margin-left: 2em; }
  @media (max-width: 35.99em) {
    .margin-l-sm {
      margin-left: 1.5em; } }

.margin-l-md {
  margin-left: 3em; }
  @media (max-width: 35.99em) {
    .margin-l-md {
      margin-left: 2.5em; } }

.margin-l-lg {
  margin-left: 4em; }
  @media (max-width: 35.99em) {
    .margin-l-lg {
      margin-left: 3.5em; } }

.margin-l-xl {
  margin-left: 5em; }
  @media (max-width: 35.99em) {
    .margin-l-xl {
      margin-left: 4.5em; } }

.margin-l-xxl {
  margin-left: 7em; }
  @media (max-width: 35.99em) {
    .margin-l-xxl {
      margin-left: 6.5em; } }

.margin-r-xs {
  margin-right: 1em; }

.margin-r-sm {
  margin-right: 2em; }
  @media (max-width: 35.99em) {
    .margin-r-sm {
      margin-right: 1.5em; } }

.margin-r-md {
  margin-right: 3em; }
  @media (max-width: 35.99em) {
    .margin-r-md {
      margin-right: 2.5em; } }

.margin-r-lg {
  margin-right: 4em; }
  @media (max-width: 35.99em) {
    .margin-r-lg {
      margin-right: 3.5em; } }

.margin-r-xl {
  margin-right: 5em; }
  @media (max-width: 35.99em) {
    .margin-r-xl {
      margin-right: 4.5em; } }

.margin-r-xxl {
  margin-right: 7em; }
  @media (max-width: 35.99em) {
    .margin-r-xxl {
      margin-right: 6.5em; } }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
.color-pink {
  color: #c40064; }

.color-pinkLight {
  color: #fdbbd9; }

.color-green {
  color: #00837c; }

.color-greenLight {
  color: #c2eee7; }

.color-orange {
  color: #c9472d; }

.color-orangeLight {
  color: #ffc89f; }

.color-blue {
  color: #006ebf; }

.color-blueLight {
  color: #cae5ff; }

.color-purple {
  color: #5d237d; }

.color-purpleLight {
  color: #ebd6f2; }

.bg-pink {
  background-color: #c40064; }

.bg-pinkLight {
  background-color: #fdbbd9; }

.bg-green {
  background-color: #00837c; }

.bg-greenLight {
  background-color: #c2eee7; }

.bg-orange {
  background-color: #c9472d; }

.bg-orangeLight {
  background-color: #ffc89f; }

.bg-blue {
  background-color: #006ebf; }

.bg-blueLight {
  background-color: #cae5ff; }

.bg-purple {
  background-color: #5d237d; }

.bg-purpleLight {
  background-color: #ebd6f2; }

/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
.color-yellow {
  color: #fcbf0a; }

.bg-yellow {
  background-color: #fcbf0a; }

/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
.color-dark {
  color: #333333; }

.color-black {
  color: #1b1b1b; }

.color-gray {
  color: #f5f3ee; }

.color-white {
  color: #FFFFFF; }

.bg-dark {
  background-color: #333333; }

.bg-black {
  background-color: #1b1b1b; }

.bg-gray {
  background-color: #f5f3ee; }

.bg-white {
  background-color: #FFFFFF; }

/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.color-link {
  color: #0664aa; }

.color-border {
  color: #c9c6c0; }

.color-success {
  color: #61BD4F; }

.color-warning {
  color: #ffc107; }

.color-error {
  color: #dd1f26; }

.bg-link {
  background-color: #0664aa; }

.bg-border {
  background-color: #c9c6c0; }

.bg-success {
  background-color: #61BD4F; }

.bg-error {
  background-color: #dd1f26; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hide {
  display: none; }

.full-width {
  width: 100%; }

.full-height {
  height: 100vh; }

.middle {
  vertical-align: middle; }

.radius-3 {
  border-radius: 3px; }

.radius-5 {
  border-radius: 5px; }

.radius-10 {
  border-radius: 10px; }

.radius-15 {
  border-radius: 15px; }

.radius-big {
  border-radius: 1000px; }

.no-border {
  border: none; }

.all-rounded {
  border-radius: 4px; }

.bottom-rounded {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.top-rounded {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

html {
  overflow-x: hidden; }

.container {
  padding: 0 4%;
  width: 100%;
  max-width: none;
  max-width: 2048px; }
  @media (min-width: 47.9375em) {
    .container {
      padding: 0 6%; } }
  @media (min-width: 62em) {
    .container {
      padding: 0 8%; } }
  @media (min-width: 75em) {
    .container {
      padding: 0 10%; } }

img {
  max-width: 100%; }

.container--text {
  max-width: 700px; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.btn {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 10px 0;
  padding: 0.5em 2em;
  background: #333333;
  font-family: "Open sans", Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 1em;
  color: #fff;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.5px;
  transform: perspective(1px) translateZ(0);
  transform: scale(1);
  transition-duration: 0.3s;
  transition-property: color;
  cursor: pointer; }
  .btn:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 4px;
    opacity: 0.1;
    transform: scaleY(0);
    transform-origin: 50% 100%;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
    transition-property: transform;
    content: ''; }
  .btn:hover, .btn:active {
    transition: 0.5s; }
  .btn:active {
    transform: scale(0.95); }
  .btn:hover:before {
    transform: scaleY(1); }
  .btn .icon {
    display: none; }

.btn--border {
  background-color: #FFFFFF;
  color: #f5f3ee;
  border: 2px solid #c9c6c0; }

.btn--borderTransparent {
  background-color: transparent;
  color: #f5f3ee;
  border: 2px solid #c9c6c0; }

.btn--flat {
  border-radius: 0;
  margin: 0;
  width: 100%; }

.btn__showMore {
  color: #0664aa;
  background-color: #FFFFFF;
  border: 1px solid #c9c6c0;
  padding-top: 1em;
  padding-bottom: 1em; }

@media all and (min-width: 0px) {
  .btn--small {
    font-size: 1em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .btn--small {
    font-size: 1em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .btn--small {
    font-size: 1em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .btn--medium {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .btn--medium {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .btn--medium {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .btn--large {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .btn--large {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .btn--large {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .btn--jumbo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .btn--jumbo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .btn--jumbo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .btn--mega {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .btn--mega {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .btn--mega {
    font-size: 1.80203em;
    line-height: 1.5; } }

.btn--fullWidth {
  width: 100%;
  display: block; }

.btn--marginRight {
  margin-right: 20px; }
  @media (max-width: 61.99em) {
    .btn--marginRight {
      margin-right: 0; } }

@media (max-width: 61.99em) {
  .btn--fullWidthMobile {
    width: 100%; } }

.buttonClickNoFocus {
  cursor: pointer; }

.btn--hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
  .btn--hover:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 4px;
    opacity: 0.2;
    transform: scaleY(1);
    transform-origin: 50% 100%;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
    transition-property: transform;
    content: ''; }

.btn--pressed {
  transform: scale(0.95); }

.btn--disabled {
  pointer-events: none;
  background-color: #f5f3ee;
  color: white; }

.iconLeft {
  margin-right: 5px; }

.iconRight {
  margin-left: 5px; }

.btn--iconLeft {
  padding-right: 25px; }
  .btn--iconLeft .iconLeft {
    display: inline-block; }

.btn--iconRight {
  padding-left: 25px; }
  .btn--iconRight .iconRight {
    display: inline-block; }

.btn--success {
  background-color: #61BD4F; }

.btn--warning {
  background-color: #ffc107; }

.btn--error {
  background-color: #dd1f26; }

.btn__category {
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #00837c;
  border: 1px solid #c9c6c0;
  transition: 0.5s;
  color: #FFFFFF;
  border: none; }

.btn__category--active {
  background-color: #c2eee7;
  color: #333333; }

button.btn {
  position: relative;
  cursor: pointer; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
@media all and (min-width: 0px) {
  .fa.fa--textMega {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textMega {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textMega {
    font-size: 3.24732em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textJumbo {
    font-size: 3.24732em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textJumbo {
    font-size: 3.24732em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textJumbo {
    font-size: 3.24732em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textHeadingOne {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textHeadingOne {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textHeadingOne {
    font-size: 1.80203em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textHeadingTwo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textHeadingTwo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textHeadingTwo {
    font-size: 1.60181em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textHeadingThree {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textHeadingThree {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textHeadingThree {
    font-size: 1.42383em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textHeadingFour {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textHeadingFour {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textHeadingFour {
    font-size: 1.26563em;
    line-height: 1.5; } }

@media all and (min-width: 0px) {
  .fa.fa--textParagraph {
    font-size: 1em;
    line-height: 1.5; } }

@media all and (min-width: 767px) {
  .fa.fa--textParagraph {
    font-size: 1em;
    line-height: 1.5; } }

@media all and (min-width: 1200px) {
  .fa.fa--textParagraph {
    font-size: 1em;
    line-height: 1.5; } }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.form__input,
.form__textarea,
.form__select {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 11px 20px;
  border: 1px solid #c9c6c0;
  color: #1b1b1b;
  transition: border-color 0.5s ease-out;
  font-family: "Open sans", Helvetica, Arial, sans-serif; }

input::placeholder,
textarea::placeholder {
  color: #333333; }

.form__select {
  background-image: url(../../images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: 99% 50%;
  background-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form__input[role="search"] {
  background-image: url(../../img/search.svg);
  background-repeat: no-repeat;
  background-position: 1% 50%;
  background-size: 25px;
  padding-left: 50px; }

.form__radio,
.form__checkbox {
  position: relative;
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #c9c6c0;
  color: #333333;
  transition: border-color 0.5s ease-out; }

.form__textarea {
  min-height: 100px; }

.form--disabled {
  background-color: #f5f3ee;
  border-color: #c9c6c0;
  pointer-events: none;
  opacity: 0.5; }

.form--error {
  border: 1px solid #dd1f26; }

.form__errorMessage {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #dd1f26;
  margin-bottom: 20px; }

.form__label {
  letter-spacing: 1px; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.image {
  width: 100%;
  max-width: 100%; }

.logo {
  max-width: 413px;
  max-height: 48px; }
  @media (max-width: 35.99em) {
    .logo.logoBig {
      display: none; } }
  .logo.logoSmall {
    display: none; }
    @media (max-width: 35.99em) {
      .logo.logoSmall {
        display: block; } }

.image--border {
  border: 1px solid #f5f3ee; }

.imageAvatar {
  /* 1:1 */
  position: relative;
  width: 100%;
  max-width: 75px;
  height: 0;
  padding-bottom: 75px; }
  .imageAvatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageLandscape {
  /* 16:9 */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }
  .imageLandscape img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imagePortrait {
  /* 4:3 */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; }
  .imagePortrait img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageSquare {
  /* 1:1 */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; }
  .imageSquare img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageSmall {
  /* 1:1 */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 25%; }
  .imageSmall img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageLandscapeSmall {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40.25%; }
  @media (max-width: 61.99em) {
    .imageLandscapeSmall {
      padding-bottom: 56.25%; } }
  .imageLandscapeSmall img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.imageFullSize {
  position: absolute;
  width: 100%;
  height: 100%; }
  @media (max-width: 61.99em) {
    .imageFullSize {
      padding-bottom: 56.25%;
      height: 0;
      position: relative; } }
  .imageFullSize img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.textParagraphIcon {
  display: flex;
  align-items: center; }
  .textParagraphIcon .icon {
    margin-right: 10px; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.form__group {
  display: flex;
  flex-direction: column; }
  .form__group .form__errorMessage {
    display: none; }

.form__group--error .form__input, .form__group--error .form__textarea {
  margin-bottom: 5px;
  border: 1px solid #dd1f26; }

.form__group--error .form__errorMessage {
  display: block; }

.textAnchorIcon {
  display: flex;
  align-items: center;
  margin-bottom: 1em; }
  .textAnchorIcon .icon {
    margin-right: 0.5em; }
  .textAnchorIcon img {
    width: 25px;
    margin-right: 0.5em; }

.filterView--disabled {
  opacity: 0; }

.filterView__container {
  display: flex; }

.filterView__option {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid #c9c6c0; }
  .filterView__option svg {
    fill: #333333;
    width: 25px;
    max-width: 25px;
    max-height: 25px;
    margin-right: 0.5em; }
  .filterView__option:first-child {
    border-right: 1px solid #c9c6c0; }
  .filterView__option:hover svg {
    fill: #0664aa; }
  .filterView__option:hover p {
    color: #0664aa; }
  .filterView__option p {
    color: #333333;
    margin: 0; }

.filterView__option--active {
  color: #0664aa;
  border-color: #0664aa !important; }
  .filterView__option--active svg {
    fill: #0664aa; }
  .filterView__option--active p {
    color: #0664aa; }

/* Profilfärger
De fem profilfärgerna (rosa, grön, orange, blå och lila) används som identitetsskapande färger och bygger bilden av en mångsidig och innovativ stad. */
/* Accentfärg
Gult är en accentfärg och används sparsmakat. Gult ska aldrig tävla med övriga färger om utrymme. */
/* Basfärger
Svart, vit och grå är basfärger. Den svarta är i en mörkgrå nyans eftrsom helt svart mot vitt ger en för stor kontrast  och en sämre läsbarhet på skärm. Den grå färgen används som bas/bakgrund för att accentueras med profilfärgerna */
/* Kompletterande färger
De kompletterande färgerna är avsedda för särskilt användande. Såsom länkar, ramlinjer på formulär, avdelare samt felmeddelanden. */
.footer {
  background-color: #333333;
  width: 100%; }
  .footer a, .footer p, .footer h1, .footer h2, .footer h3, .footer h4, .footer li {
    color: #FFFFFF; }
  .footer a {
    text-decoration: underline; }
  .footer .menu-list {
    border-bottom: 1px solid #c9c6c0;
    padding-bottom: 1em;
    margin-bottom: 1em; }
    .footer .menu-list a {
      text-decoration: none; }

.footer__container {
  padding-top: 4em;
  padding-bottom: 4em; }
  @media (max-width: 35.99em) {
    .footer__container {
      padding-top: 3.5em;
      padding-bottom: 3.5em; } }

.footer__column {
  margin-bottom: 30px; }

.footer__heading {
  margin-top: 1em;
  margin-bottom: 1em; }

.footer__list {
  margin-bottom: 20px; }

.footer__Imageicon {
  max-width: 50px; }

.footer__heading {
  font-family: "Stockholm Type Regular"; }

.heroHeader {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 3em;
  padding-bottom: 3em; }
  @media (max-width: 35.99em) {
    .heroHeader {
      padding-top: 2.5em;
      padding-bottom: 2.5em; } }
  @media (max-width: 61.99em) {
    .heroHeader {
      background-image: none !important;
      background-color: #333333 !important; } }

.heroHeader__container {
  padding: 0 4%;
  width: 100%;
  max-width: none;
  max-width: 2048px;
  margin: 0 auto; }
  @media (min-width: 47.9375em) {
    .heroHeader__container {
      padding: 0 6%; } }
  @media (min-width: 62em) {
    .heroHeader__container {
      padding: 0 8%; } }
  @media (min-width: 75em) {
    .heroHeader__container {
      padding: 0 10%; } }

.heroHeader__content {
  width: 100%; }

.heroHeader_textBlock {
  background: rgba(0, 0, 0, 0.6);
  padding: 4em;
  display: flex;
  max-width: 2048px;
  margin: 0 auto; }
  @media (max-width: 35.99em) {
    .heroHeader_textBlock {
      padding: 3.5em; } }
  @media (max-width: 61.99em) {
    .heroHeader_textBlock {
      padding: 0;
      background: #333333; } }

.heroHeader_heading,
.heroHeader_text {
  color: #FFFFFF; }

.navigation {
  width: 100%;
  background-color: #FFFFFF;
  padding-top: 2em;
  padding-bottom: 2em; }
  @media (max-width: 35.99em) {
    .navigation {
      padding-top: 1.5em;
      padding-bottom: 1.5em; } }
  .navigation .navigation__container {
    margin: 0 auto;
    padding: 0 4%;
    width: 100%;
    max-width: none;
    max-width: 2048px; }
    @media (min-width: 47.9375em) {
      .navigation .navigation__container {
        padding: 0 6%; } }
    @media (min-width: 62em) {
      .navigation .navigation__container {
        padding: 0 8%; } }
    @media (min-width: 75em) {
      .navigation .navigation__container {
        padding: 0 10%; } }
  .navigation .menu {
    display: flex;
    align-content: center;
    margin-top: 30px; }
  .navigation .menu__listItem {
    margin-right: 20px; }
    .navigation .menu__listItem .textAnchor {
      color: #333333;
      padding-bottom: 2.5px;
      border-bottom: 5px solid transparent; }
      .navigation .menu__listItem .textAnchor:hover {
        border-color: #333333; }
  .navigation .menu__listItem--active .textAnchor {
    border-color: #333333; }

.modal {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
  transition: 0.5s;
  pointer-events: none;
  visibility: hidden;
  opacity: 0; }
  .modal .fa-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 2em;
    color: #f5f3ee; }

.modal__content {
  box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
  background-color: #FFFFFF;
  padding: 2em;
  margin: 0 15px;
  max-width: 700px;
  width: 100%; }
  @media (max-width: 35.99em) {
    .modal__content {
      padding: 1.5em; } }

.modal--active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: auto;
  visibility: visible;
  opacity: 1; }

.modalDelete__info p {
  margin: 0; }

.modalDelete__option .btn {
  width: 49%; }

.modalLoading {
  display: flex;
  align-items: center; }
  .modalLoading .modalLoading__heading {
    margin: 0;
    margin-left: 20px; }

.breadcrumb {
  background-color: #c2eee7;
  width: 100%; }
  .breadcrumb a {
    color: #333333; }

.breadcrumb__container {
  padding-top: 1em;
  padding-bottom: 1em; }

.breadcrumb__pageList {
  display: flex;
  align-content: center;
  justify-content: center; }

.breadcrumb__page {
  color: #333333;
  text-decoration: underline; }
  .breadcrumb__page:hover {
    text-decoration: underline; }

.breadcrumb__page:after {
  content: "/";
  padding: 0 2.5px;
  display: inline-block;
  text-decoration: none; }

.breadcrumb__page:last-child:after {
  display: none; }

.breadcrumb__page--active {
  text-decoration: none; }

.websiteColumn {
  background-color: #f5f3ee;
  margin-bottom: 3em;
  padding: 1em; }
  @media (max-width: 35.99em) {
    .websiteColumn {
      margin-bottom: 2.5em; } }

.websiteColumn__image {
  border: 1px solid #c9c6c0; }
  .websiteColumn__image .imageLandscape {
    overflow: hidden; }
    .websiteColumn__image .imageLandscape img {
      height: auto; }

.websiteColumn__excerpt {
  padding: 1em; }
  .websiteColumn__excerpt h3 a {
    hyphens: auto; }
  .websiteColumn__excerpt .textAnchorIcon:last-child {
    margin: 0; }
  .websiteColumn__excerpt .textAnchorIcon a {
    word-break: break-all; }

.websiteColumn__heading {
  margin-bottom: 20px; }
  .websiteColumn__heading a {
    color: #333333; }

.websiteFeatured {
  background-color: #f5f3ee;
  border: 1px solid #f5f3ee;
  display: flex;
  align-items: center;
  margin-bottom: 3em;
  padding: 1em; }
  @media (max-width: 35.99em) {
    .websiteFeatured {
      margin-bottom: 2.5em; } }
  @media (max-width: 61.99em) {
    .websiteFeatured {
      flex-direction: column; } }

.websiteFeatured__image {
  border: 1px solid #c9c6c0;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative; }
  @media (max-width: 61.99em) {
    .websiteFeatured__image {
      flex: none;
      max-width: none;
      width: 100%; } }
  .websiteFeatured__image .imageLandscape {
    overflow: hidden; }
    .websiteFeatured__image .imageLandscape img {
      height: auto; }

.websiteFeatured__excerpt {
  padding: 4em;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (max-width: 35.99em) {
    .websiteFeatured__excerpt {
      padding: 3.5em; } }
  @media (max-width: 61.99em) {
    .websiteFeatured__excerpt {
      flex: none;
      max-width: none;
      width: 100%;
      padding: 1em; } }
  .websiteFeatured__excerpt h3 a {
    hyphens: auto; }
  .websiteFeatured__excerpt .textAnchorIcon:last-child {
    margin: 0; }

.websiteFeatured__heading {
  margin-bottom: 20px; }
  .websiteFeatured__heading a {
    color: #333333; }

.websiteList {
  background-color: #f5f3ee;
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  padding: 1em; }
  @media (max-width: 35.99em) {
    .websiteList {
      margin-bottom: 1.5em; } }

.websiteList__image {
  border: 1px solid #c9c6c0;
  flex: 0 0 20%;
  max-width: 20%; }
  @media (max-width: 61.99em) {
    .websiteList__image {
      flex: 0 0 30%;
      max-width: 30%; } }

.websiteList__excerpt {
  flex: 0 0 80%;
  max-width: 80%;
  margin-left: 20px; }
  @media (max-width: 61.99em) {
    .websiteList__excerpt {
      flex: 0 0 70%;
      max-width: 70%; } }
  .websiteList__excerpt h3 a {
    hyphens: auto; }
  .websiteList__excerpt .textAnchorIcon:last-child {
    margin: 0; }

.websiteList__heading {
  margin-bottom: 20px; }
  .websiteList__heading a {
    color: #333333; }

.searchPanel__btnSearch {
  padding: 0.75em 0; }

.searchPanel {
  background-color: #f5f3ee;
  width: 100%; }

.searchPanel__container {
  padding-top: 4em;
  padding-bottom: 4em; }
  @media (max-width: 35.99em) {
    .searchPanel__container {
      padding-top: 3.5em;
      padding-bottom: 3.5em; } }

.category__listing {
  display: flex;
  flex-wrap: wrap; }

.category__item {
  margin-right: 10px; }

.websiteFilterView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2em; }
  @media (max-width: 35.99em) {
    .websiteFilterView {
      margin-bottom: 1.5em; } }
  .websiteFilterView .textHeadingOne {
    margin: 0; }
  @media (max-width: 35.99em) {
    .websiteFilterView {
      flex-direction: column;
      align-items: flex-start; }
      .websiteFilterView .textHeadingOne {
        margin-bottom: 0.5em; } }
  @media (max-width: 35.99em) {
    .websiteFilterView .filterView {
      width: 100%; }
    .websiteFilterView .filterView__option {
      width: 50%; } }

.sidebar {
  background-color: #f5f3ee;
  padding: 1em; }

/*.navigationSidebar {
    background-color: $color-gray;
    width: 100%;
    .menu__listItem {
        padding: 10px;
        border-bottom: 1px solid $color-border;
        transition: 0.5s;
        &:hover {
						background-color: $color-greenLight;
						a {
							color: $color-dark !important;
						}
        }
        a {
            display: block;
        }
    }
    .menu__listItem--active {
				background-color: $color-greenLight;
				a {
				color: $color-dark !important;
			}
    }
}*/
.websiteNavigation {
  width: 100%;
  position: relative;
  z-index: 3;
  background-color: #FFFFFF;
  border-bottom: 1px solid #c9c6c0; }
  .websiteNavigation .websiteNavigation__container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center; }
    @media (max-width: 61.99em) {
      .websiteNavigation .websiteNavigation__container {
        flex-direction: column;
        align-items: flex-start;
        -webkit-flex-direction: column;
        -ms-flex-direction: column; } }
  .websiteNavigation * {
    color: #0664aa; }

.websiteNavigation__name {
  padding-right: 1em;
  padding-left: 1em;
  position: relative;
  flex: 1; }
  .websiteNavigation__name .textHeadingOne {
    margin: 0;
    font-size: 1.2em;
    font-family: "Stockholm Type Regular";
    color: #333333; }
  @media (max-width: 61.99em) {
    .websiteNavigation__name {
      padding: 20px 40px !important;
      text-align: center;
      width: 100%; } }

.fa.websiteNavigation__hamburger {
  display: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
  line-height: 0;
  cursor: pointer; }
  @media (max-width: 61.99em) {
    .fa.websiteNavigation__hamburger {
      display: inline; } }

.fa.websiteNavigation__hamburger--active {
  transform: rotate(180deg); }

@media (max-width: 61.99em) {
  .websiteNavigation__menu {
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
    transition: 0.5s;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 100%;
    position: absolute;
    top: 61px;
    background-color: #FFFFFF; } }

.websiteNavigation__menu .menu {
  display: flex;
  align-items: stretch; }
  @media (max-width: 61.99em) {
    .websiteNavigation__menu .menu {
      flex-direction: column; } }

.websiteNavigation__menu .menu__listItem {
  padding: 1em;
  border-left: 1px solid #c9c6c0;
  display: flex; }
  @media (max-width: 61.99em) {
    .websiteNavigation__menu .menu__listItem {
      border: none;
      border-bottom: 1px solid #c9c6c0; }
      .websiteNavigation__menu .menu__listItem:first-child {
        border-top: 1px solid #c9c6c0; } }

.websiteNavigation__menu .textAnchorIcon {
  margin: 0; }

.websiteNavigation__menu--active {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  height: auto; }

.websiteNavigation__btnYear {
  margin-left: 0;
  margin-left: 20px;
  padding: 10px 20px;
  border: 1px solid #c9c6c0;
  background-color: #FFFFFF;
  color: #333333; }

.websiteNavigation__year .textParagraph {
  margin: 0; }

.websiteYearList {
  position: fixed;
  height: 100%;
  width: 260px;
  right: -260px;
  top: 0;
  z-index: 1;
  transition: all 0.5s, padding-top 0s;
  overflow-y: auto;
  border-left: 1px solid #c9c6c0;
  background-color: #FFFFFF; }
  @media (max-width: 61.99em) {
    .websiteYearList {
      width: 100%;
      right: -100%;
      border: none; } }
  .websiteYearList .menu__listItem {
    padding: 10px;
    border-bottom: 1px solid #c9c6c0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .websiteYearList .menu__listItem a {
      width: 100%; }

.websiteYearList--active {
  right: 0; }

.websiteMetadataList {
  position: fixed;
  height: 100%;
  width: 50%;
  right: -50%;
  top: 0;
  border-left: 1px solid #c9c6c0;
  background-color: #FFFFFF;
  z-index: 1;
  transition: all 0.5s, padding-top 0s;
  overflow-y: auto; }
  @media (max-width: 61.99em) {
    .websiteMetadataList {
      width: 100%;
      right: -100%;
      border: none; } }

.websiteMetadataList--active {
  right: 0; }

.dashboardLogin {
  box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1);
  background-color: #FFFFFF; }

.dashboardLogin__container {
  padding: 4em; }
  @media (max-width: 35.99em) {
    .dashboardLogin__container {
      padding: 3.5em; } }

.dashboardLogin__logo {
  margin-bottom: 2em; }
  @media (max-width: 35.99em) {
    .dashboardLogin__logo {
      margin-bottom: 1.5em; } }

.dashboardList {
  background-color: #FFFFFF;
  border-top: 1px solid #f5f3ee;
  border-left: 1px solid #f5f3ee;
  border-right: 1px solid #f5f3ee; }
  .dashboardList:first-child, .dashboardList:last-child {
    border-bottom: 1px solid #f5f3ee; }

.dashboardList__container {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.dashboardList__info p {
  margin: 0; }

.dashboardList__delete {
  font-size: 1.5em !important;
  cursor: pointer; }

.dashboardList__option .fa {
  font-size: 1.5em !important;
  cursor: pointer; }

.dashboardList__option .dashboardList__refresh {
  margin-right: 20px; }

.dashboardImport {
  background-color: #f5f3ee; }

.dashboardImport__container {
  padding: 4em; }
  @media (max-width: 35.99em) {
    .dashboardImport__container {
      padding: 3.5em; } }

.dashboardImport__logo {
  margin-bottom: 2em;
  max-width: 400px;
  display: block;
  margin: 0 auto; }
  @media (max-width: 35.99em) {
    .dashboardImport__logo {
      margin-bottom: 1.5em; } }

.notFound {
  text-align: center;
  width: 100%; }
  .notFound .textHeadingOne {
    font-size: 15em; }
    @media (max-width: 61.99em) {
      .notFound .textHeadingOne {
        font-size: 10em; } }
  .notFound .textAnchorIcon {
    justify-content: center; }
